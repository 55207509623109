// Intro
import introMove from './steps/intro-move.en.gif';
import introSayHello from './steps/intro-say-hello.en.gif';

// Text to Speech
import speechAddExtension from './steps/talking-1-add-extension.en.gif';
import speechSaySomething from './steps/speech-say-something.en.gif';
import speechSetVoice from './steps/speech-set-voice.en.gif';
import speechMoveAround from './steps/speech-move-around.en.gif';
import speechAddBackdrop from './steps/speech-add-backdrop.en.gif';
import speechAddSprite from './steps/speech-add-sprite.en.gif';
import speechSong from './steps/speech-song.en.gif';
import speechChangeColor from './steps/speech-change-color.en.gif';
import speechSpin from './steps/speech-spin.en.gif';
import speechGrowShrink from './steps/speech-grow-shrink.en.gif';

// Cartoon Network
import cnShowCharacter from './steps/cn-show-character.gif';
import cnSay from './steps/cn-say.en.gif';
import cnGlide from './steps/cn-glide.en.gif';
import cnPickSprite from './steps/cn-pick-sprite.gif';
import cnCollect from './steps/cn-collect.en.gif';
import cnVariable from './steps/add-variable.en.gif';
import cnScore from './steps/cn-score.en.gif';
import cnBackdrop from './steps/cn-backdrop.en.gif';

// Add sprite
import addSprite from './steps/add-sprite.en.gif';

// Animate a name
import namePickLetter from './steps/name-pick-letter.en.gif';
import namePlaySound from './steps/name-play-sound.en.gif';
import namePickLetter2 from './steps/name-pick-letter2.en.gif';
import nameChangeColor from './steps/name-change-color.en.gif';
import nameSpin from './steps/name-spin.en.gif';
import nameGrow from './steps/name-grow.en.gif';

// Make-Music
import musicPickInstrument from './steps/music-pick-instrument.en.gif';
import musicPlaySound from './steps/music-play-sound.en.gif';
import musicMakeSong from './steps/music-make-song.en.gif';
import musicMakeBeat from './steps/music-make-beat.en.gif';
import musicMakeBeatbox from './steps/music-make-beatbox.en.gif';

// Chase-Game
import chaseGameAddBackdrop from './steps/chase-game-add-backdrop.gif';
import chaseGameAddSprite1 from './steps/chase-game-add-sprite1.en.gif';
import chaseGameRightLeft from './steps/chase-game-right-left.en.gif';
import chaseGameUpDown from './steps/chase-game-up-down.en.gif';
import chaseGameAddSprite2 from './steps/chase-game-add-sprite2.en.gif';
import chaseGameMoveRandomly from './steps/chase-game-move-randomly.en.gif';
import chaseGamePlaySound from './steps/chase-game-play-sound.en.gif';
import chaseGameAddVariable from './steps/add-variable.en.gif';
import chaseGameChangeScore from './steps/chase-game-change-score.en.gif';

// Clicker-Game (Pop Game)
import popGamePickSprite from './steps/pop-game-pick-sprite.en.gif';
import popGamePlaySound from './steps/pop-game-play-sound.en.gif';
import popGameAddScore from './steps/add-variable.en.gif';
import popGameChangeScore from './steps/pop-game-change-score.en.gif';
import popGameRandomPosition from './steps/pop-game-random-position.en.gif';
import popGameChangeColor from './steps/pop-game-change-color.en.gif';
import popGameResetScore from './steps/pop-game-reset-score.en.gif';

// Animate A Character
import animateCharPickBackdrop from './steps/animate-char-pick-backdrop.en.gif';
import animateCharPickSprite from './steps/animate-char-pick-sprite.en.gif';
import animateCharSaySomething from './steps/animate-char-say-something.en.gif';
import animateCharAddSound from './steps/animate-char-add-sound.en.gif';
import animateCharTalk from './steps/animate-char-talk.en.gif';
import animateCharMove from './steps/animate-char-move.en.gif';
import animateCharJump from './steps/animate-char-jump.en.gif';
import animateCharChangeColor from './steps/animate-char-change-color.en.gif';

// Tell A Story
import storyPickBackdrop from './steps/story-pick-backdrop.gif';
import storyPickSprite from './steps/story-pick-sprite.gif';
import storySaySomething from './steps/story-say-something.en.gif';
import storyPickSprite2 from './steps/story-pick-sprite2.gif';
import storyFlip from './steps/story-flip.en.gif';
import storyConversation from './steps/story-conversation.en.gif';
import storyPickBackdrop2 from './steps/story-pick-backdrop2.gif';
import storySwitchBackdrop from './steps/story-switch-backdrop.en.gif';
import storyHideCharacter from './steps/story-hide-character.en.gif';
import storyShowCharacter from './steps/story-show-character.en.gif';

// Video Sensing
import videoAddExtension from './steps/video-add-extension.en.gif';
import videoPet from './steps/video-pet.en.png';
import videoAnimate from './steps/video-animate.en.png';
import videoPop from './steps/video-pop.en.png';

// Make it Fly
import flyChooseBackdrop from './steps/fly-choose-backdrop.en.gif';
import flyChooseCharacter from './steps/fly-choose-character.en.gif';
import flySaySomething from './steps/fly-say-something.en.gif';
import flyMoveArrows from './steps/fly-make-interactive.en.gif';
import flyChooseObject from './steps/fly-object-to-collect.en.gif';
import flyFlyingObject from './steps/fly-flying-heart.en.gif';
import flySelectFlyingSprite from './steps/fly-select-flyer.en.jpg';
import flyAddScore from './steps/add-variable.en.gif';
import flyKeepScore from './steps/fly-keep-score.en.gif';
import flyAddScenery from './steps/fly-choose-scenery.en.gif';
import flyMoveScenery from './steps/fly-move-scenery.en.gif';
import flySwitchLooks from './steps/fly-switch-costume.en.gif';

// Pong
import pongAddBackdrop from './steps/pong-add-backdrop.en.png';
import pongAddBallSprite from './steps/pong-add-ball-sprite.png';
import pongBounceAround from './steps/pong-bounce-around.en.gif';
import pongAddPaddle from './steps/pong-add-a-paddle.en.gif';
import pongMoveThePaddle from './steps/pong-move-the-paddle.en.gif';
import pongSelectBallSprite from './steps/pong-select-ball.png';
import pongAddMoreCodeToBall from './steps/pong-add-code-to-ball.en.png';
import pongAddAScore from './steps/add-variable.en.gif';
import pongChooseScoreFromMenu from './steps/pong-choose-score.en.png';
import pongInsertChangeScoreBlock from './steps/pong-insert-change-score.en.png';
import pongResetScore from './steps/pong-reset-score.en.png';
import pongAddLineSprite from './steps/pong-add-line.en.gif';
import pongGameOver from './steps/pong-game-over.en.png';

// Imagine a World
import imagineTypeWhatYouWant from './steps/imagine-type-what-you-want.en.png';
import imagineClickGreenFlag from './steps/imagine-click-green-flag.en.png';
import imagineChooseBackdrop from './steps/imagine-choose-backdrop.png';
import imagineChooseSprite from './steps/imagine-choose-any-sprite.png';
import imagineFlyAround from './steps/imagine-fly-around.en.png';
import imagineChooseAnotherSprite from './steps/imagine-choose-another-sprite.png';
import imagineLeftRight from './steps/imagine-left-right.en.png';
import imagineUpDown from './steps/imagine-up-down.en.png';
import imagineChangeCostumes from './steps/imagine-change-costumes.en.png';
import imagineGlideToPoint from './steps/imagine-glide-to-point.en.png';
import imagineGrowShrink from './steps/imagine-grow-shrink.en.png';
import imagineChooseAnotherBackdrop from './steps/imagine-choose-another-backdrop.png';
import imagineSwitchBackdrops from './steps/imagine-switch-backdrops.en.gif';
import imagineRecordASound from './steps/imagine-record-a-sound.en.gif';
import imagineChooseSound from './steps/imagine-choose-sound.en.png';

// Add a Backdrop
import addBackdrop from './steps/add-backdrop.en.png';

// Add Effectsf
import addEffects from './steps/add-effects.en.png';

// Hide and Show
import hideAndShow from './steps/hide-show.en.png';

// Switch Costumes
import switchCostumes from './steps/switch-costumes.en.png';

// Change Size
import changeSize from './steps/change-size.en.png';

// Spin
import spinTurn from './steps/spin-turn.en.png';
import spinPointInDirection from './steps/spin-point-in-direction.en.png';

// Record a Sound
import recordASoundSoundsTab from './steps/record-a-sound-sounds-tab.en.png';
import recordASoundClickRecord from './steps/record-a-sound-click-record.en.png';
import recordASoundPressRecordButton from './steps/record-a-sound-press-record-button.en.png';
import recordASoundChooseSound from './steps/record-a-sound-choose-sound.en.png';
import recordASoundPlayYourSound from './steps/record-a-sound-play-your-sound.en.png';

// Use Arrow Keys
import moveArrowKeysLeftRight from './steps/move-arrow-keys-left-right.en.png';
import moveArrowKeysUpDown from './steps/move-arrow-keys-up-down.en.png';

// Glide Around
import glideAroundBackAndForth from './steps/glide-around-back-and-forth.en.png';
import glideAroundPoint from './steps/glide-around-point.en.png';

// Code a Cartoon
import codeCartoonSaySomething from './steps/code-cartoon-01-say-something.en.png';
import codeCartoonAnimate from './steps/code-cartoon-02-animate.en.gif';
import codeCartoonSelectDifferentCharacter from './steps/code-cartoon-03-select-different-character.png';
import codeCartoonUseMinusSign from './steps/code-cartoon-04-use-minus-sign.en.gif';
import codeCartoonGrowShrink from './steps/code-cartoon-05-grow-shrink.en.gif';
import codeCartoonSelectDifferentCharacter2 from './steps/code-cartoon-06-select-another-different-character.png';
import codeCartoonJump from './steps/code-cartoon-07-jump.en.gif';
import codeCartoonChangeScenes from './steps/code-cartoon-08-change-scenes.en.gif';
import codeCartoonGlideAround from './steps/code-cartoon-09-glide-around.en.gif';
import codeCartoonChangeCostumes from './steps/code-cartoon-10-change-costumes.en.gif';
import codeCartoonChooseMoreCharacters from './steps/code-cartoon-11-choose-more-characters.en.jpg';

// Talking Tales
import talesAddExtension from './steps/talking-1-add-extension.en.gif';
import talesChooseSprite from './steps/talking-2-choose-sprite.en.png';
import talesSaySomething from './steps/talking-3-say-something.en.gif';
import talesChooseBackdrop from './steps/talking-4-choose-backdrop.en.png';
import talesSwitchBackdrop from './steps/talking-5-switch-backdrop.en.gif';
import talesChooseAnotherSprite from './steps/talking-6-choose-another-sprite.en.png';
import talesMoveAround from './steps/talking-7-move-around.en.gif';
import talesChooseAnotherBackdrop from './steps/talking-8-choose-another-backdrop.en.png';
import talesAnimateTalking from './steps/talking-9-animate.en.gif';
import talesChooseThirdBackdrop from './steps/talking-10-choose-third-backdrop.en.png';
import talesChooseSound from './steps/talking-11-choose-sound.en.gif';
import talesDanceMoves from './steps/talking-12-dance-moves.en.gif';
import talesAskAnswer from './steps/talking-13-ask-and-answer.en.gif';

// Modules
import modulesSlideDimmer from './modules/kizakli_dim.png';
import modulesUsbPower from './modules/usb.png';
import modulesBuzzer from './modules/zil.png';
import modulesBargraph from './modules/sirali_led.png';
import modulesLed from './modules/kablolu_led.png';
import modulesFan from './modules/fan.png';
import modulesCounter from './modules/sayac.png';
import modulesLightSensor from './modules/isik_algilayici.png';
import modulesServoMotor from './modules/servo_motor.png';
import modulesWire from './modules/baglanti_kablosu.png';
import modulesLatch from './modules/sinyal_sabitleyici.png';
import modulesProximitySensor from './modules/mesafe_algilayici.png';
import modulesMotionSensor from './modules/hareket_algilayici.png';
import modulesRemoteControl from './modules/uzaktan_kumanda.png';
import modulesButton from './modules/buton.png';
import modulesSplitter from './modules/dagitici.png';
import modulesDcMotor from './modules/motor.png';
import modulesInverter from './modules/sinyal_donusturucu.png';
import modulesSoundSensor from './modules/ses_algilayici.png';
import modulesSignalConductor from './modules/sinyal_iletici.png';
import modulesCodingModule from './modules/kodlama_modulu.png';
import modulesUltrasonicDistanceSensor from './modules/ultrasonik_mesafe_algilayici.png';
import modulesSoilMoistureSensor from './modules/toprak_nem_sensoru.png';
import modulesSolarPowerModule from './modules/gunes_enerjisi_modulu.png';
import modulesAirTemperatureHumiditySensor from './modules/sicaklik_ve_nem_sensoru.png';

const enImages = {
    // Modules
    twinAirTemperatureHumiditySensor: modulesAirTemperatureHumiditySensor,
    twinSolarPowerModule: modulesSolarPowerModule,
    twinSoilMoistureSensor: modulesSoilMoistureSensor,
    twinUltrasonicDistanceSensor: modulesUltrasonicDistanceSensor,
    twinCodingModule: modulesCodingModule,
    twinSignalConductor: modulesSignalConductor,
    twinSoundSensor: modulesSoundSensor,
    twinSlideDimmer: modulesSlideDimmer,
    twinUsbPower: modulesUsbPower,
    twinBuzzer: modulesBuzzer,
    twinBargraph: modulesBargraph,
    twinLed: modulesLed,
    twinFan: modulesFan,
    twinCounter: modulesCounter,
    twinLightSensor: modulesLightSensor,
    twinServoMotor: modulesServoMotor,
    twinWire: modulesWire,
    twinLatch: modulesLatch,
    twinProximitySensor: modulesProximitySensor,
    twinMotionSensor: modulesMotionSensor,
    twinRemoteControl: modulesRemoteControl,
    twinButton: modulesButton,
    twinSplitter: modulesSplitter,
    twinDcMotor: modulesDcMotor,
    twinInverter: modulesInverter,

    // Intro
    introMove: introMove,
    introSayHello: introSayHello,

    // Text to Speech
    speechAddExtension: speechAddExtension,
    speechSaySomething: speechSaySomething,
    speechSetVoice: speechSetVoice,
    speechMoveAround: speechMoveAround,
    speechAddBackdrop: speechAddBackdrop,
    speechAddSprite: speechAddSprite,
    speechSong: speechSong,
    speechChangeColor: speechChangeColor,
    speechSpin: speechSpin,
    speechGrowShrink: speechGrowShrink,

    // Cartoon Network
    cnShowCharacter: cnShowCharacter,
    cnSay: cnSay,
    cnGlide: cnGlide,
    cnPickSprite: cnPickSprite,
    cnCollect: cnCollect,
    cnVariable: cnVariable,
    cnScore: cnScore,
    cnBackdrop: cnBackdrop,

    // Add sprite
    addSprite: addSprite,

    // Animate a name
    namePickLetter: namePickLetter,
    namePlaySound: namePlaySound,
    namePickLetter2: namePickLetter2,
    nameChangeColor: nameChangeColor,
    nameSpin: nameSpin,
    nameGrow: nameGrow,

    // Make-Music
    musicPickInstrument: musicPickInstrument,
    musicPlaySound: musicPlaySound,
    musicMakeSong: musicMakeSong,
    musicMakeBeat: musicMakeBeat,
    musicMakeBeatbox: musicMakeBeatbox,

    // Chase-Game
    chaseGameAddBackdrop: chaseGameAddBackdrop,
    chaseGameAddSprite1: chaseGameAddSprite1,
    chaseGameRightLeft: chaseGameRightLeft,
    chaseGameUpDown: chaseGameUpDown,
    chaseGameAddSprite2: chaseGameAddSprite2,
    chaseGameMoveRandomly: chaseGameMoveRandomly,
    chaseGamePlaySound: chaseGamePlaySound,
    chaseGameAddVariable: chaseGameAddVariable,
    chaseGameChangeScore: chaseGameChangeScore,

    // Make-A-Pop/Clicker Game
    popGamePickSprite: popGamePickSprite,
    popGamePlaySound: popGamePlaySound,
    popGameAddScore: popGameAddScore,
    popGameChangeScore: popGameChangeScore,
    popGameRandomPosition: popGameRandomPosition,
    popGameChangeColor: popGameChangeColor,
    popGameResetScore: popGameResetScore,

    // Animate A Character
    animateCharPickBackdrop: animateCharPickBackdrop,
    animateCharPickSprite: animateCharPickSprite,
    animateCharSaySomething: animateCharSaySomething,
    animateCharAddSound: animateCharAddSound,
    animateCharTalk: animateCharTalk,
    animateCharMove: animateCharMove,
    animateCharJump: animateCharJump,
    animateCharChangeColor: animateCharChangeColor,

    // Tell A Story
    storyPickBackdrop: storyPickBackdrop,
    storyPickSprite: storyPickSprite,
    storySaySomething: storySaySomething,
    storyPickSprite2: storyPickSprite2,
    storyFlip: storyFlip,
    storyConversation: storyConversation,
    storyPickBackdrop2: storyPickBackdrop2,
    storySwitchBackdrop: storySwitchBackdrop,
    storyHideCharacter: storyHideCharacter,
    storyShowCharacter: storyShowCharacter,

    // Video Sensing
    videoAddExtension: videoAddExtension,
    videoPet: videoPet,
    videoAnimate: videoAnimate,
    videoPop: videoPop,

    // Make it Fly
    flyChooseBackdrop: flyChooseBackdrop,
    flyChooseCharacter: flyChooseCharacter,
    flySaySomething: flySaySomething,
    flyMoveArrows: flyMoveArrows,
    flyChooseObject: flyChooseObject,
    flyFlyingObject: flyFlyingObject,
    flySelectFlyingSprite: flySelectFlyingSprite,
    flyAddScore: flyAddScore,
    flyKeepScore: flyKeepScore,
    flyAddScenery: flyAddScenery,
    flyMoveScenery: flyMoveScenery,
    flySwitchLooks: flySwitchLooks,

    // Pong
    pongAddBackdrop: pongAddBackdrop,
    pongAddBallSprite: pongAddBallSprite,
    pongBounceAround: pongBounceAround,
    pongAddPaddle: pongAddPaddle,
    pongMoveThePaddle: pongMoveThePaddle,
    pongSelectBallSprite: pongSelectBallSprite,
    pongAddMoreCodeToBall: pongAddMoreCodeToBall,
    pongAddAScore: pongAddAScore,
    pongChooseScoreFromMenu: pongChooseScoreFromMenu,
    pongInsertChangeScoreBlock: pongInsertChangeScoreBlock,
    pongResetScore: pongResetScore,
    pongAddLineSprite: pongAddLineSprite,
    pongGameOver: pongGameOver,

    // Imagine a World
    imagineTypeWhatYouWant: imagineTypeWhatYouWant,
    imagineClickGreenFlag: imagineClickGreenFlag,
    imagineChooseBackdrop: imagineChooseBackdrop,
    imagineChooseSprite: imagineChooseSprite,
    imagineFlyAround: imagineFlyAround,
    imagineChooseAnotherSprite: imagineChooseAnotherSprite,
    imagineLeftRight: imagineLeftRight,
    imagineUpDown: imagineUpDown,
    imagineChangeCostumes: imagineChangeCostumes,
    imagineGlideToPoint: imagineGlideToPoint,
    imagineGrowShrink: imagineGrowShrink,
    imagineChooseAnotherBackdrop: imagineChooseAnotherBackdrop,
    imagineSwitchBackdrops: imagineSwitchBackdrops,
    imagineRecordASound: imagineRecordASound,
    imagineChooseSound: imagineChooseSound,

    // Add a Backdrop
    addBackdrop: addBackdrop,

    // Add Effects
    addEffects: addEffects,

    // Hide and Show
    hideAndShow: hideAndShow,

    // Switch Costumes
    switchCostumes: switchCostumes,

    // Change Size
    changeSize: changeSize,

    // Spin
    spinTurn: spinTurn,
    spinPointInDirection: spinPointInDirection,

    // Record a Sound
    recordASoundSoundsTab: recordASoundSoundsTab,
    recordASoundClickRecord: recordASoundClickRecord,
    recordASoundPressRecordButton: recordASoundPressRecordButton,
    recordASoundChooseSound: recordASoundChooseSound,
    recordASoundPlayYourSound: recordASoundPlayYourSound,

    // Use Arrow Keys
    moveArrowKeysLeftRight: moveArrowKeysLeftRight,
    moveArrowKeysUpDown: moveArrowKeysUpDown,

    // Glide Around
    glideAroundBackAndForth: glideAroundBackAndForth,
    glideAroundPoint: glideAroundPoint,

    // Code a Cartoon
    codeCartoonSaySomething: codeCartoonSaySomething,
    codeCartoonAnimate: codeCartoonAnimate,
    codeCartoonSelectDifferentCharacter: codeCartoonSelectDifferentCharacter,
    codeCartoonUseMinusSign: codeCartoonUseMinusSign,
    codeCartoonGrowShrink: codeCartoonGrowShrink,
    codeCartoonSelectDifferentCharacter2: codeCartoonSelectDifferentCharacter2,
    codeCartoonJump: codeCartoonJump,
    codeCartoonChangeScenes: codeCartoonChangeScenes,
    codeCartoonGlideAround: codeCartoonGlideAround,
    codeCartoonChangeCostumes: codeCartoonChangeCostumes,
    codeCartoonChooseMoreCharacters: codeCartoonChooseMoreCharacters,

    // Talking Tales
    talesAddExtension: talesAddExtension,
    talesChooseSprite: talesChooseSprite,
    talesSaySomething: talesSaySomething,
    talesAskAnswer: talesAskAnswer,
    talesChooseBackdrop: talesChooseBackdrop,
    talesSwitchBackdrop: talesSwitchBackdrop,
    talesChooseAnotherSprite: talesChooseAnotherSprite,
    talesMoveAround: talesMoveAround,
    talesChooseAnotherBackdrop: talesChooseAnotherBackdrop,
    talesAnimateTalking: talesAnimateTalking,
    talesChooseThirdBackdrop: talesChooseThirdBackdrop,
    talesChooseSound: talesChooseSound,
    talesDanceMoves: talesDanceMoves
};

export {enImages};
