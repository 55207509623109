import React from 'react';
import {FormattedMessage} from 'react-intl';

// Tutorial thumbnails: Avoid using any text that would need to be
// translated in thumbnails.

const servedFilePath = '../../static/example-projects/';
const thumbnailPath = servedFilePath + 'thumbnails/';
const codePath = servedFilePath + 'codes/';
// const codePath = 'https://twinner.blob.core.windows.net/app/web-platform-experiments/';

const exampleProjects = {
    birthdayCard: {
        img: thumbnailPath + 'birthday-card.png',
        code: codePath + 'birthday-card.sb3',
        name: (
            <FormattedMessage
                defaultMessage="Birthday Card"
                id="gui.examples.birthdayCard"
            />
        )
    },
    codeYourSong: {
        img: thumbnailPath + 'code-your-song.png',
        code: codePath + 'code-your-song.sb3',
        tags: ['music'],
        name: (
            <FormattedMessage
                defaultMessage="Code Your Song"
                id="gui.examples.codeYourSong"
            />
        )
    },
    colorPalette: {
        img: thumbnailPath + 'color-palette.png',
        code: codePath + 'color-palette.sb3',
        tags: ['art'],
        name: (
            <FormattedMessage
                defaultMessage="Color Palette"
                id="gui.examples.colorPalette"
            />
        )
    },
    discoBall: {
        img: thumbnailPath + 'disco-ball.png',
        code: codePath + 'disco-ball.sb3',
        name: (
            <FormattedMessage
                defaultMessage="Disco Ball"
                id="gui.examples.discoBall"
            />
        )
    },
    emergencyLight: {
        img: thumbnailPath + 'emergency-light.png',
        code: codePath + 'emergency-light.sb3',
        name: (
            <FormattedMessage
                defaultMessage="Emergency Light"
                id="gui.examples.emergencyLight"
            />
        )
    },
    isThereAnybodyOutThere: {
        img: thumbnailPath + 'is-there-anybody-out-there.png',
        code: codePath + 'is-there-anybody-out-there.sb3',
        name: (
            <FormattedMessage
                defaultMessage="Is There Anybody Out There?"
                id="gui.examples.isThereAnybodyOutThere"
            />
        )
    },
    jukeBox: {
        img: thumbnailPath + 'jukebox.png',
        code: codePath + 'jukebox.sb3',
        name: (
            <FormattedMessage
                defaultMessage="Juke Box"
                id="gui.examples.jukebox"
            />
        )
    },
    lengthMeter: {
        img: thumbnailPath + 'length-meter.png',
        code: codePath + 'length-meter.sb3',
        name: (
            <FormattedMessage
                defaultMessage="Length Meter"
                id="gui.examples.lengthMeter"
            />
        )
    },
    lightsOfTheCity: {
        img: thumbnailPath + 'lights-of-the-city.png',
        code: codePath + 'lights-of-the-city.sb3',
        name: (
            <FormattedMessage
                defaultMessage="Lights of The City"
                id="gui.examples.lightsOfTheCity"
            />
        )
    },
    lineTracker: {
        img: thumbnailPath + 'line-tracker.png',
        code: codePath + 'line-tracker.sb3',
        name: (
            <FormattedMessage
                defaultMessage="Line Tracker"
                id="gui.examples.lineTracker"
            />
        )
    },
    nightLight: {
        img: thumbnailPath + 'night-light.png',
        code: codePath + 'night-light.sb3',
        name: (
            <FormattedMessage
                defaultMessage="Night Light"
                id="gui.examples.nightLight"
            />
        )
    },
    piano: {
        img: thumbnailPath + 'piano.png',
        code: codePath + 'piano.sb3',
        tags: ['music'],
        name: (
            <FormattedMessage
                defaultMessage="Piano"
                id="gui.examples.piano"
            />
        )
    },
    radar: {
        img: thumbnailPath + 'radar.png',
        code: codePath + 'radar.sb3',
        name: (
            <FormattedMessage
                defaultMessage="Radar"
                id="gui.examples.radar"
            />
        )
    },
    robotWorm: {
        img: thumbnailPath + 'robot-worm.png',
        code: codePath + 'robot-worm.sb3',
        tags: ['games'],
        name: (
            <FormattedMessage
                defaultMessage="Robot Worm"
                id="gui.examples.robotWorm"
            />
        )
    },
    smartSecurityDoor: {
        img: thumbnailPath + 'smart-security-door.png',
        code: codePath + 'smart-security-door.sb3',
        name: (
            <FormattedMessage
                defaultMessage="Smart Security Door"
                id="gui.examples.smartSecurityDoor"
            />
        )
    },
    snowGlobe: {
        img: thumbnailPath + 'snow-globe.png',
        code: codePath + 'snow-globe.sb3',
        name: (
            <FormattedMessage
                defaultMessage="Snow Globe"
                id="gui.examples.snowGlobe"
            />
        )
    },
    trafficLight: {
        img: thumbnailPath + 'traffic-light.png',
        code: codePath + 'traffic-light.sb3',
        name: (
            <FormattedMessage
                defaultMessage="Traffic Light"
                id="gui.examples.trafficLight"
            />
        )
    },
    truthOrDare: {
        img: thumbnailPath + 'truth-or-dare.png',
        code: codePath + 'truth-or-dare.sb3',
        tags: ['games'],
        name: (
            <FormattedMessage
                defaultMessage="Truth or Dare"
                id="gui.examples.truthOrDare"
            />
        )
    },
    zoetrope: {
        img: thumbnailPath + 'zoetrope.png',
        code: codePath + 'zoetrope.sb3',
        tags: ['art'],
        name: (
            <FormattedMessage
                defaultMessage="Zoetrope"
                id="gui.examples.zoetrope"
            />
        )
    }
};

export default exampleProjects;
