import React from 'react';

import mobileIcon from './mobile-popup.svg';

import styles from './mobile-popup.css';

const MobilePopupComponent = () => {
    let os = '';
    let link = '';

    if (typeof window !== 'undefined') {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.userAgentData?.platform || window.navigator.platform;
        const macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

        if (macosPlatforms.includes(platform)) {
            os = 'Mac OS';
        } else if (iosPlatforms.includes(platform)) {
            os = 'iOS';
        } else if (windowsPlatforms.includes(platform)) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (/Linux/.test(platform)) {
            os = 'Linux';
        }
    }

    if (os === 'iOS') {
        link = 'https://apps.apple.com/tr/app/twin-bilim-kodlama/id1447423867?l=tr';
    } else if (os === 'Android') {
        link = 'https://play.google.com/store/apps/details?id=tr.org.yga.twin.android';
    }

    if (os === '' || link === '') return null;

    return (
        <a
            href={link}
            className={styles.mobilePopup}
            target="_blank"
            rel="noreferrer"
        >
            <img
                className={''}
                src={mobileIcon}
                width={170}
            />
        </a>
    );
};

export default MobilePopupComponent;
