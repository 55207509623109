exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mobile-popup_mobile-popup_31A15 {\n    display: inline-block;\n    position: fixed;\n    left: calc(100vw - 170px);\n    top: calc(100vh - 50px);\n    top: calc(100dvh - 50px);\n    z-index: 47;\n}\n", ""]);

// exports
exports.locals = {
	"mobile-popup": "mobile-popup_mobile-popup_31A15",
	"mobilePopup": "mobile-popup_mobile-popup_31A15"
};