import editorMessages from 'scratch-l10n/locales/editor-msgs';

export default {
    ...editorMessages,
    en: {
        ...editorMessages.en,
        'gui.menubar.educatorPlatform': 'Educator Platform',
        'gui.menubar.exploreBlocks': 'Explore the Blocks',
        'gui.controls.record': 'Record'
    },
    tr: {
        ...editorMessages.tr,
        'gui.controls.bluetooth': 'Bluetooth',
        'gui.menuBar.examplesLibrary': 'Örnek Projeler',
        'gui.menuBar.tutorialsLibrary': 'Eğitici Dersler',
        'gui.cards.all-examples': 'Örnek Projeler',
        'gui.cards.all-tutorials': 'Eğitici Dersler',
        'gui.tipsLibrary.examples': 'Bir Örnek Proje Seç',
        'gui.tipsLibrary.tutorials': 'Bir Eğitici Ders Seç',
        'gui.examples.emergencyLight': 'Acil Durum Lambası',
        'gui.examples.lengthMeter': 'Boy Ölçer',
        'gui.examples.lightsOfTheCity': 'Şehrin Işıkları',
        'gui.examples.smartSecurityDoor': 'Akıllı Güvenlik Kapısı',
        'gui.examples.piano': 'Piyano',
        'gui.examples.radar': 'Radar',
        'gui.examples.codeYourSong': 'Şarkını Kodla',
        'gui.examples.isThereAnybodyOutThere': 'Sokakta Biri Mi Var?',
        'gui.examples.trafficLight': 'Trafik Lambası',
        'gui.examples.snowGlobe': 'Kar Küresi',
        'gui.examples.zoetrope': 'Film Makinesi',
        'gui.examples.truthOrDare': 'Doğruluk mu Cesaret mi?',
        'gui.examples.robotWorm': 'Robot Solucan',
        'gui.examples.jukebox': 'Müzik Kutusu',
        'gui.examples.birthdayCard': 'Doğum Günü Kartı',
        'gui.examples.nightLight': 'Gece Lambası',
        'gui.examples.discoBall': 'Disko Topu',
        'gui.examples.colorPalette': 'Renk Paleti',
        'gui.extension.pose_face.name': 'Yüz Algılama',
        'gui.extension.pose_face.description': 'Kamerayla yüz hareketini algıla.',
        'gui.extension.pose_body.name': 'Vücut Algılama',
        'gui.extension.pose_body.description': 'Kamerayla vücut pozisyonunu algıla.',
        'gui.soundEditor.fadeOut': 'Sesi azalt',
        'gui.menubar.educatorPlatform': 'Öğretmen Platformu',
        'gui.menubar.exploreBlocks': 'Blokları Keşfet',
        'gui.controls.record': 'Kayıt',
        'teachableMachine.categoryName': 'Teachable Machine',
        'teachableMachine.useModel': 'modeli kullan [MODEL_URL]',
        'teachableMachine.whenModelMatches': 'model, [CLASS_NAME]i algıladığında',
        'teachableMachine.modelPrediction': 'model tahmini',
        'teachableMachine.modelMatches': 'tahmin [CLASS_NAME]',
        'teachableMachine.modelConfidence': 'ahmin güvenilirliği',
        'teachableMachine.classConfidence': '[CLASS_NAME] için tahmini oran',
        'teachableMachine.predictionStateClass1': 'Sınıf 1',
        'textClassification.categoryName': 'Metin Sınıflandırma',
        'textClassification.editModel': 'Modeli Düzenle',
        'textClassification.loadOrSaveModel': 'Modeli Yükle / Kaydet',
        'textClassification.text': 'METİN',
        'textClassification.hello': 'Merhaba',
        'textClassification.howAreYou': 'Nasılsın?',
        'textClassification.enterTextOrAnswerBlock': 'Metin veya cevap bloğu',
        'textClassification.ifTextMatchesClass': '[TEXT] [CLASS_NAME] ile eşleşiyor mu?',
        'textClassification.getModelPrediction': '[TEXT] için sınıfı tahmin et',
        'textClassification.getModelConfidence': '[TEXT] için tahmin edilen sınıfın tahmin oranı',
        'textClassification.confidencetrue': '[TEXT]in [LABEL] olma olasılığı',
        'textClassification.sentimentScore': '[TEXT] için duygu puanı',
        'textClassification.speakText': '[TEXT] de',
        'textClassification.askSpeechRecognition': '[PROMPT] diye sor, ardından yanıtı dinle',
        'textClassification.getRecognizedSpeech': 'yanıt',
        'textClassification.onHeardSound': 'ses > [THRESHOLD] olduğunda',
        'textClassification.toxicityLabels.toxic': 'rahatsız edici',
        'textClassification.toxicityLabels.severeToxicity': 'çok rahatsız edici',
        'textClassification.toxicityLabels.identityAttack': 'kimlik temelli bir saldırı',
        'textClassification.toxicityLabels.insult': 'aşağılayıcı',
        'textClassification.toxicityLabels.threat': 'tehdit edici',
        'textClassification.toxicityLabels.obscene': 'küfürlü',
        'textClassification.toxicityLabels.sexualExplicit': 'cinsel içerikli',
        'textClassification.areYouSure': 'Tüm etiketleri silmek istediğinize emin misiniz?',
        'textClassification.noClassesInputted': 'Sınıf girilmedi',
        'textClassification.alreadyDone': 'Zaten yapıldı.',
        'textClassification.noLabels': 'Etiket yok',
        'videoSensing.partLabels.nose': 'burun',
        'videoSensing.partLabels.leftEye': 'sağ göz',
        'videoSensing.partLabels.rightEye': 'sol göz',
        'videoSensing.partLabels.leftEar': 'sağ kulak',
        'videoSensing.partLabels.rightEar': 'sol kulak',
        'videoSensing.partLabels.leftShoulder': 'sağ omuz',
        'videoSensing.partLabels.rightShoulder': 'sol omuz',
        'videoSensing.partLabels.leftElbow': 'sağ dirsek',
        'videoSensing.partLabels.rightElbow': 'sol dirsek',
        'videoSensing.partLabels.leftWrist': 'sağ bilek',
        'videoSensing.partLabels.rightWrist': 'sol bilek',
        'videoSensing.partLabels.leftHip': 'sağ kalça',
        'videoSensing.partLabels.rightHip': 'sol kalça',
        'videoSensing.partLabels.leftKnee': 'sağ diz',
        'videoSensing.partLabels.rightKnee': 'sol diz',
        'videoSensing.partLabels.leftAnkle': 'sağ ayak bileği',
        'videoSensing.partLabels.rightAnkle': 'sol ayak bileği',
        'videoSensing.emotionLabels.joy': 'neşeli',
        'videoSensing.emotionLabels.sadness': 'üzgün',
        'videoSensing.emotionLabels.disgust': 'iğrenmiş',
        'videoSensing.emotionLabels.anger': 'öfkeli',
        'videoSensing.emotionLabels.fear': 'korkmuş',
        'videoSensing.emotionAllLabels.joy': 'neşe',
        'videoSensing.emotionAllLabels.sadness': 'üzüntü',
        'videoSensing.emotionAllLabels.disgust': 'iğrenme',
        'videoSensing.emotionAllLabels.contempt': 'aşağılama',
        'videoSensing.emotionAllLabels.anger': 'öfke',
        'videoSensing.emotionAllLabels.fear': 'korku',
        'videoSensing.emotionAllLabels.surprise': 'şaşkınlık',
        'videoSensing.emotionAllLabels.valence': 'değerli olmak',
        'videoSensing.emotionAllLabels.engagement': 'bağlılık',
        'videoSensing.expressionLabels.smile': 'gülümseme',
        'videoSensing.expressionLabels.mouthOpen': 'ağız açık',
        'videoSensing.expressionLabels.eyeClosure': 'göz kapanması',
        'videoSensing.expressionLabels.browRaise': 'kaş kaldırma',
        'videoSensing.expressionLabels.lipPucker': 'ıslık çalma',
        'videoSensing.expressionLabels.eyeWiden': 'göz genişlemesi',
        'videoSensing.expressionLabels.browFurrow': 'kaş çatma',
        'videoSensing.expressionLabels.noseWrinkle': 'burun kırışıklığı',
        'videoSensing.expressionLabels.upperLipRaise': 'üst dudak kaldırma',
        'videoSensing.expressionLabels.lipCornerDepressor': 'dudak köşesi çekme',
        'videoSensing.expressionLabels.chinRaise': 'çene kaldırma',
        'videoSensing.expressionLabels.smirk': 'sırıtış',
        'videoSensing.expressionLabels.attention': 'dikkat',
        'videoSensing.expressionLabels.lidTighten': 'göz kapağı sıkıştırma',
        'videoSensing.expressionLabels.jawDrop': 'çene düşürme',
        'videoSensing.expressionLabels.dimpler': 'yanak gamzesi',
        'videoSensing.expressionLabels.cheekRaise': 'yanak kaldırma',
        'videoSensing.expressionLabels.lipStretch': 'dudak germe',
        'videoSensing.affdexPointLabels.leftEar': 'sol kulak',
        'videoSensing.affdexPointLabels.leftChin': 'sol çene',
        'videoSensing.affdexPointLabels.chin': 'çene',
        'videoSensing.affdexPointLabels.rightChin': 'sağ çene',
        'videoSensing.affdexPointLabels.rightEar': 'sağ kulak',
        'videoSensing.affdexPointLabels.leftOuterEyebrow': 'sol kaş dışı',
        'videoSensing.affdexPointLabels.leftEyebrow': 'sol kaş',
        'videoSensing.affdexPointLabels.leftInnerEyebrow': 'sol kaş içi',
        'videoSensing.affdexPointLabels.rightInnerEyebrow': 'sağ kaş içi',
        'videoSensing.affdexPointLabels.rightEyebrow': 'sağ kaş',
        'videoSensing.affdexPointLabels.rightOuterEyebrow': 'sağ kaş dışı',
        'videoSensing.affdexPointLabels.noseBridge': 'burun köprüsü',
        'videoSensing.affdexPointLabels.noseTip': 'burun ucu',
        'videoSensing.affdexPointLabels.leftNostril': 'sol burun deliği',
        'videoSensing.affdexPointLabels.rightNostril': 'sağ burun deliği',
        'videoSensing.affdexPointLabels.leftOuterEyeCrease': 'sol göz kıvrımı dışı',
        'videoSensing.affdexPointLabels.leftInnerEyeCrease': 'sol göz kıvrımı içi',
        'videoSensing.affdexPointLabels.rightInnerEyeCrease': 'sağ göz kıvrımı içi',
        'videoSensing.affdexPointLabels.rightOuterEyeCrease': 'sağ göz kıvrımı dışı',
        'videoSensing.affdexPointLabels.leftMouthCrease': 'sol ağız kıvrımı',
        'videoSensing.affdexPointLabels.leftUpperLipPoint': 'sol üst dudak noktası',
        'videoSensing.affdexPointLabels.upperLip': 'üst dudak',
        'videoSensing.affdexPointLabels.rightUpperLipPoint': 'sağ üst dudak noktası',
        'videoSensing.affdexPointLabels.rightMouthCrease': 'sağ ağız kıvrımı',
        'videoSensing.affdexPointLabels.rightLowerLipPoint': 'sağ alt dudak noktası',
        'videoSensing.affdexPointLabels.lowerLip': 'alt dudak',
        'videoSensing.affdexPointLabels.leftLowerLipPoint': 'sol alt dudak noktası',
        'videoSensing.affdexPointLabels.upperLipBottom': 'üst dudak alt kısmı',
        'videoSensing.affdexPointLabels.lowerLipTop': 'alt dudak üst kısmı',
        'videoSensing.affdexPointLabels.leftUpperEyelid': 'sol üst göz kapağı',
        'videoSensing.affdexPointLabels.leftLowerEyelid': 'sol alt göz kapağı',
        'videoSensing.affdexPointLabels.rightUpperEyelid': 'sağ üst göz kapağı',
        'videoSensing.affdexPointLabels.rightLowerEyelid': 'sağ alt göz kapağı',
        'face.categoryName': 'Yüz Algılama',
        'face.blocks.affdexGoToPart': '[AFFDEX_POINT]a git',
        'face.blocks.affdexWhenExpression': '[EXPRESSION] algılandığında',
        'face.blocks.affdexExpressionAmount': '[EXPRESSION] miktarı',
        'face.blocks.affdexIsExpression': '[EXPRESSION] ifade ediyor',
        'face.blocks.affdexWhenEmotion': '[EMOTION] his algılandığında',
        'face.blocks.affdexEmotionAmount': '[EMOTION_ALL] seviyesi',
        'face.blocks.affdexIsTopEmotion': '[EMOTION] hissetmek',
        'posenet.categoryName': 'Vücut Pozu Algılama',
        'posenet.blocks.affdexGoToPart': '[PART]a git',
        'twin.modules.usbPower.name': 'Güç Modülü',
        'twin.modules.buzzer.name': 'Zil',
        'twin.modules.bargraph.name': 'Sıralı Led',
        'twin.modules.led.name': 'Led',
        'twin.modules.fan.name': 'Fan',
        'twin.modules.counter.name': 'Sayaç',
        'twin.modules.lightSensor.name': 'Işık Algılayıcı',
        'twin.modules.servo.name': 'Servo Motor',
        'twin.modules.slideDimmer.name': 'Kızaklı Dim',
        'twin.modules.wire.name': 'Bağlantı Kablosu',
        'twin.modules.latch.name': 'Sinyal Sabitleyici',
        'twin.modules.proximity.name': 'Mesafe Algılayıcı',
        'twin.modules.motionSensor.name': 'Hareket Algılayıcı',
        'twin.modules.remoteControl.name': 'Uzaktan Kumanda',
        'twin.modules.button.name': 'Buton',
        'twin.modules.splitter.name': 'Dağıtıcı',
        'twin.modules.dcMotor.name': 'DC Motor',
        'twin.modules.inverter.name': 'Dönüştürücü',
        'twin.modules.soundSensor.name': 'Ses Algılayıcı',
        'twin.modules.signalConductor.name': 'Sinyal İletici',
        'twin.modules.codingModule.name': 'Kodlama Modülü',
        'twin.modules.ultrasonicDistanceSensor.name': 'Ultrasonik Mesafe Algılayıcı',
        'twin.modules.soilMoistureSensor.name': 'Toprak Nem Sensörü',
        'twin.modules.solarPowerModule.name': 'Güneş Enerjisi',
        'twin.modules.airTemperatureHumiditySensor.name': 'Sıcaklık ve Nem Sensörü',
        'textModelModal.editText': 'Yazı Modelini Düzenle',
        'textModelModal.addLabel': 'Etiket Ekle',
        'textModelModal.clearAll': 'Hepsini Sil',
        'textModelModal.done': 'Tamamlandı',
        'textModelModal.editLabel': 'Etiketi Düzenle',
        'textModelModal.doneEditing': 'Etiket Düzenleme Tamamlandı',
        'textModelModal.deleteLabel': 'Etiketi Sil',
        'textModelModal.addExample': 'Örnek Ekle',
        'textModelModal.examples': 'örnek',
        'textModelModal.class': 'Sınıf',
        'classifierModelModal.editClassifier': 'Sınıflandırıcı Modeli Düzenle',
        'classifierModelModal.done': 'Tamamlandı',
        'classifierModelModal.editLabel': 'Etiketi Düzenle',
        'classifierModelModal.doneEditing': 'Etiket Düzenleme Tamamlandı',
        'classifierModelModal.deleteLabel': 'Etiketi Sil',
        'classifierModelModal.addExample': 'Örnek Ekle',
        'classifierModelModal.exportClassifier': 'Sınıflandırıcı Dışa Aktar',
        'classifierModelModal.importClassifier': 'Sınıflandırıcı İçeri Yükle',
        'classifierModelModal.examples': 'örnek'
    }
};
